import axios from "axios";

export default axios.create({
  // baseURL: "http://qzaz.lan/api/v1",
  baseURL: "https://m7w.com/rs/v1",
  timeout: 10000,
  headers: {
    Expires: "0",
    "Cache-Control": "no-cache, no-store",
    Control: "must-revalidate max-age=0",
  },
});
